import React, { Fragment, useState, useEffect } from "react"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
import FormTextArea from "elements/Form/FormTextarea"

import address from "../../../../static/data/philippineLocations.json"
import AddressTypeChoices from "./Address/AddressTypeChoices"

const INPUT_STREET_PLACEHOLDER = "Unit/House No., Building, Street, Barangay"
const SELECT_CITY_PLACEHOLDER = "Select city..."

const Address = ({
  name,
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
}) => {
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])

  useEffect(() => {
    const initializeProvinces = () => {
      let options = address.map(remapProvinces)
      const metroManila = options.filter(
        ({ value }) => value === "Metro Manila"
      )
      if (isNationwide) setProvinces(options)
      else setProvinces(metroManila)
    }
    initializeProvinces()
    const { Cities } = address.filter(loc => loc.Province === "Metro Manila")[0]
    setListOfCity(sortCities(Cities))
  }, [isNationwide])

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })

  const sortCities = cities => {
    const sortedCities = cities
      .map(({ City }) => ({
        value: City,
        label: City,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }
  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter(loc => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setFieldValue(`${name}.city`)
  }

  let cityValue = !!values[name] ? values[name].city : values.city
  let provinceValue = !!values[name] ? values[name].province : values.province

  return (
    <Fragment>
      {fieldNames.includes("addressType") && (
        <AddressTypeChoices
          addressTypeName={`${name}.addressType`}
          values={values}
          setFieldValue={setFieldValue}
          isRequired={isRequired}
        />
      )}
      <FormInput
        name={`${name}.streetAddress`}
        label="Street Address"
        helper={helper}
        placeholder={INPUT_STREET_PLACEHOLDER}
        isRequired={isRequired}
      />
      <FormSelect
        name={`${name}.province`}
        label="Province"
        isRequired={isRequired}
        value={provinceValue}
        options={listOfProvince}
        onChange={handleOnChange}
      />
      <FormSelect
        isClearable={true}
        name={`${name}.city`}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={cityValue}
        isRequired={isRequired}
        options={listOfCity}
      />
      {fieldNames.includes("notes") && (
        <FormTextArea
          name={`${name}.notes`}
          label="Delivery Notes"
          placeholder="Green gate"
          isRequired={false}
        />
      )}
    </Fragment>
  )
}

export default Address
