import * as Yup from "yup"
import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_YEAR,
  INVALID_MOBILE_NUMBER,
  yearNow,
} from "services/validations"

const patientEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow - 18, "You must be at least 18 years old")
      .required(REQUIRED_MESSAGE),
  }),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  deliveryAddress: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  secondaryContactFirstName: Yup.string().required(REQUIRED_MESSAGE),
  secondaryContactLastName: Yup.string().required(REQUIRED_MESSAGE),
  secondaryContactMobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  contactedThrough: Yup.array().min(1, REQUIRED_MESSAGE),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  hospitalName: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherHospital: Yup.string().when("hospitalName", {
    is: hospitalName => hospitalName?.value === "Other",
    then: Yup.string().required("This field is required"),
  }),
})

const doctorEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  prcNumber: Yup.string().required(REQUIRED_MESSAGE),
  specialization: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherSpecialization: Yup.string().when("specialization", {
    is: specialization => specialization?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hospitalClinic: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherHospital: Yup.string().when("hospitalClinic", {
    is: hospitalClinic => hospitalClinic?.value === "Other",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hospitalDepartment: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherDepartment: Yup.string().when("hospitalDepartment", {
    is: hospitalDepartment =>
      hospitalDepartment?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
})

export const getValidationSchema = module => {
  if (module === "patient") return patientEnrollmentSchema
  return doctorEnrollmentSchema
}
