import React, { Fragment, useRef } from "react"
import { Field, ErrorMessage } from "formik"
import classNames from "classnames"
import styles from "../utils/elements.module.scss"
import { getFollowUpQuestionData, generateFormField } from "./services/form"
import { camelize } from "humps"

const FormRadio = ({
  name,
  options,
  value,
  onChange,
  title,
  helper,
  isInline,
  className,
  disabled,
  isRequired,
  hideOptional,
  followUpQuestions = [],
  formFields,
  formValues,
  setFieldValue,
  isFollowUpQuestion,
}) => {
  const fieldRef = useRef(null)

  // const handleScrollCallback = () => {
  //   fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
  // }

  // useEffect(() => {
  //   if (isFollowUpQuestion) handleScrollCallback()
  // }, [isFollowUpQuestion])

  const handleChange = (form, option) => event => {
    const { setFieldValue } = form
    if (event.target.checked) setFieldValue(name, event.target.value)
    if (onChange) onChange(event)
  }

  const RadioButton = ({ form, option, index }) => (
    <div className="mb-1" id={`option${index}`}>
      <input
        className="radio is-checkradio"
        id={`option${index + 1}RadioButton${name}`}
        type="radio"
        name={name}
        value={option}
        onChange={handleChange(form, option)}
        checked={option === value}
        disabled={disabled}
      />
      <label
        className={classNames(
          "radio-label is-size-5",
          styles["form__radioLabel"],
          {
            "mr-2": !isInline,
          }
        )}
        for={`option${index + 1}RadioButton${name}`}
      >
        {option}
      </label>
    </div>
  )

  return (
    <div className="mb-2">
      <Field name={name}>
        {({ form }) => (
          <Fragment>
            {title && (
              <label
                ref={fieldRef}
                className={classNames(
                  `label mr-1 is-size-5 has-text-weight-normal`,
                  {
                    "form__radioLabel--displayInline": isInline,
                  }
                )}
              >
                {title}{" "}
                {!isRequired && !hideOptional && (
                  <span className="has-text-grey is-italic"> (Optional)</span>
                )}
              </label>
            )}
            {!!helper && (
              <span className={classNames("help has-text-weight-normal mb-1")}>
                {helper}
              </span>
            )}
            <div
              className={classNames(className?.radioGroup || "", {
                "is-flex": isInline,
              })}
            >
              {options.map((option, index) => (
                <Fragment key={index}>
                  <RadioButton form={form} option={option} index={index} />
                  {followUpQuestions?.map(followUpQuestion => {
                    const getFormField = getFollowUpQuestionData({
                      followUpQuestion,
                      formFields: formFields,
                    })

                    if (
                      value === option &&
                      getFormField?.referenceAnswer === camelize(option)
                    )
                      return (
                        <div className="notification is-light ml-2 mt-1">
                          {generateFormField({
                            formField: {
                              ...getFormField,
                              disabled: disabled,
                            },
                            formFields,
                            values: formValues,
                            setFieldValue,
                          })}
                        </div>
                      )
                    return null
                  })}
                </Fragment>
              ))}
            </div>
            <p className="help is-danger mt-0 mb-1">
              <ErrorMessage name={name} />
            </p>
          </Fragment>
        )}
      </Field>
    </div>
  )
}

export default FormRadio
