import React, { Fragment } from "react"
import classNames from "classnames"
import { FieldArray } from "formik"

import styles from "../utils/elements.module.scss"
import { generateFormField } from "./services/form"

const FormFieldArray = ({
  supportingDetailKey,
  otherInputProps,
  setFieldValue,
  labelsHeader,
  inputsHeader,
  formFields,
  inputProps,
  values,
  fields,
  name,
}) => {
  return (
    <Fragment>
      <section className="columns is-vcentered">
        <section className="column is-two-fifths">
          <h5 className={classNames(styles["fieldArray__header"])}>
            {labelsHeader}
          </h5>
        </section>
        <section className="column">
          <h5 className={classNames(styles["fieldArray__header"])}>
            {inputsHeader}
          </h5>
        </section>
      </section>
      <FieldArray name={name}>
        {() => (
          <section>
            {fields.map((form, index) => {
              return (
                <section className="columns is-vcentered" key={index}>
                  <section className="column is-two-fifths pl-0 mb-0 pb-0-mobile">
                    <label
                      className={classNames(
                        "label has-text-weight-normal is-size-5 pt-0 pb-2 column pb-0-mobile mb-0-mobile"
                      )}
                    >
                      {form}
                    </label>
                  </section>
                  <section className="column pt-0-mobile">
                    {form.includes("Other") &&
                      generateFormField({
                        setFieldValue,
                        formField: {
                          ...otherInputProps,
                          name: `${name}[${index}].otherName`,
                        },
                        formFields,
                        values,
                      })}
                    {generateFormField({
                      setFieldValue,
                      formField: {
                        ...inputProps,
                        name: `${name}[${index}].${supportingDetailKey}`,
                      },
                      formFields,
                      values,
                    })}
                  </section>
                </section>
              )
            })}
          </section>
        )}
      </FieldArray>
    </Fragment>
  )
}

export default FormFieldArray
