import React from "react"

import Address from "../Address"
import FormDate from "../FormDate"
import FormRadio from "../FormRadio"
import FormInput from "../FormInput"
import FormSelect from "../FormSelect"
import FormCheckbox from "../FormCheckbox"
import FormFieldArray from "../FormFieldArray"
import HospitalSelect from "../HospitalSelect"
import DiagnosisSelect from "../DiagnosisSelect"
import FormButtonSelect from "../FormButtonSelect"

export const generateFormField = config => {
  let { formField, formFields, values, setFieldValue, onChange } = config
  switch (formField?.type) {
    case "text":
    case "tel":
    case "email":
    case "number":
      return (
        <FormInput
          type={formField?.type === "text" ? "" : formField?.type}
          isFollowUpQuestion
          {...formField}
        />
      )
    case "date":
      return (
        <FormDate
          values={values[formField?.name]}
          onChange={onChange}
          {...formField}
        />
      )
    case "checkbox":
      return (
        <FormCheckbox
          values={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          disabledOptions={["SMS"]}
          {...formField}
        />
      )
    case "radioButton":
      return (
        <FormButtonSelect
          title={formField?.label}
          name={formField?.name}
          options={formField.options}
          values={values[formField?.name]}
          isRequired
        />
      )
    case "radio":
      return (
        <FormRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "select":
      return (
        <FormSelect
          name={formField?.name}
          value={values[formField?.name]}
          formValues={values}
          setFieldValue={setFieldValue}
          formFields={formFields}
          {...formField}
        />
      )
    case "address":
      return (
        <Address
          setFieldValue={setFieldValue}
          values={values}
          {...formField}
          isNationwide
          isRequired
        />
      )
    case "hospital":
      return (
        <HospitalSelect
          values={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "fieldarray":
      return (
        <FormFieldArray
          setFieldValue={setFieldValue}
          formFields={formFields}
          values={values}
          {...formField}
        />
      )
    case "diagnosis":
      return <DiagnosisSelect values={values} {...formField} />
    default:
      return
  }
}

export const getFollowUpQuestionData = config => {
  let { followUpQuestion, formFields } = config
  let followUpData = formFields.filter(formField => {
    return followUpQuestion === formField.name
  })

  if (followUpData.length > 0) return followUpData[0]
  else return {}
}
