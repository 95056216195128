import React, { useState, useEffect } from "react"

import FormSelect from "elements/Form/FormSelect"
import FormInput from "./FormInput"

import { handleOnHospitalChange } from "./services/hospital"
import hospitals from "./utils/hopitals.json"
import hospitalDepartments from "./utils/departments.json"

const HospitalSelect = ({
  name,
  values,
  helper,
  valueType,
  inclusions,
  fieldNames,
  isRequired,
  setFieldValue,
}) => {
  const [departments, setDepartments] = useState([])

  let filteredHospitals = hospitals
  if (inclusions) {
    filteredHospitals = hospitals.filter(hospital =>
      inclusions.includes(hospital.value)
    )
  }

  const handleSetDepartment = () => {
    setDepartments(
      hospitalDepartments.find(
        department => department.hospital === values[name].value
      )
    )
  }

  useEffect(() => {
    handleSetDepartment()
  })

  let defaultHelper =
    "Please select 'Other' if you can't find your clinic or hospital in the list."

  return (
    <section>
      <FormSelect
        name={name}
        options={filteredHospitals}
        value={values[name]}
        helper={helper || defaultHelper}
        label="Clinic or Hospital"
        isRequired={isRequired}
        placeholder="Philippine General Hospital"
        onChange={handleOnHospitalChange(name)}
        hideOptional={!isRequired}
      />
      {values[name]?.value === "Other" && (
        <FormInput
          name={`otherHospital`}
          value={values.otherHospital}
          label="Please indicate your clinic or hospital"
          placeholder="Philippine General Hospital"
          isRequired={isRequired}
          hideOptional={!isRequired}
        />
      )}
      {fieldNames?.includes("department") && (
        <FormSelect
          name="hospitalDepartment"
          options={departments.departments}
          label="Department"
          placeholder="Adult Cardiology"
          setFieldValue={setFieldValue}
          value={values.hospitalDepartment}
          isRequired
        />
      )}
      {values?.hospitalDepartment?.value === "Other (please specify)" && (
        <FormInput
          name={`otherDepartment`}
          value={values.otherDepartment}
          label="Please indicate your department"
          placeholder="Adult Cardiology"
          isRequired
        />
      )}
    </section>
  )
}

export default HospitalSelect
