import React from "react"

import FormSelect from "elements/Form/FormSelect"
import FormInput from "./FormInput"

const HospitalSelect = ({ name, values, isRequired, options, label }) => {
  return (
    <section>
      <FormSelect
        name={name}
        options={options}
        value={values[name]}
        label={label}
        isRequired={isRequired}
      />
      {values[name]?.value === "Other (please specify)" && (
        <FormInput
          name="otherDiagnosis"
          value={values.otherDiagnosis}
          label="Please indicate your specific condition"
          placeholder="Please indicate your specific condition"
          isRequired={isRequired}
          hideOptional={!isRequired}
        />
      )}
    </section>
  )
}

export default HospitalSelect
