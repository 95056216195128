import React, { useContext } from "react"
import { Formik, Form } from "formik"

import FormCheckbox from "elements/Form/FormCheckbox"

import { AppContext } from "../../context/AppContext"

const FinancialAssessmentAddon = () => {
  const { dispatch, state } = useContext(AppContext)

  let declarationBlurb = (
    <span>
      I would like to receive an update whenever my patients successfully apply
      for a diagnostic subsidy{" "}
      <span className="has-text-grey is-italic"> (Optional)</span>
    </span>
  )

  return (
    <div className="mt-5 mb-3">
      <Formik initialValues={{ ...state }}>
        {({ values }) => (
          <Form>
            <FormCheckbox
              name="wouldLikeToReceiveUpdates"
              values={values.wouldLikeToReceiveUpdates}
              options={[declarationBlurb]}
              onChange={event => {
                dispatch({
                  type: "SAVE_DOCTOR_ENROLLMENT_DECLARATION",
                  payload: event.target.checked ? [declarationBlurb] : [],
                })
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FinancialAssessmentAddon
