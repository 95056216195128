import React from "react"

import DoctorEnrollmentAddon from "../Addons/DoctorEnrollmentAddons"

const EnrollmentAddons = ({ module }) => {
  switch (module) {
    case "doctor":
      return <DoctorEnrollmentAddon />
    default:
      return null
  }
}

export default EnrollmentAddons
