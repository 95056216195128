import React from "react"
import classNames from "classnames"
import { ErrorMessage, useField } from "formik"

const FormButtonSelect = ({
  title,
  name,
  isRequired,
  hideOptional,
  helper,
  options = [],
  values = [],
  isMultipleSelect,
  handleChangeCallback,
}) => {
  const [, , helpers] = useField(name)

  const handleOnSelect = event => {
    let tempValue = isMultipleSelect ? [...values] : []
    const targetValue = event.target.value
    if (values.includes(targetValue))
      tempValue = tempValue.filter(item => item !== targetValue)
    else tempValue.push(targetValue)
    helpers.setValue(tempValue)

    if (handleChangeCallback) {
      handleChangeCallback(tempValue[0])
    }
  }

  return (
    <div className="mb-2">
      {title && (
        <label className={classNames(`label mr-1 has-text-weight-normal`)}>
          {title}{" "}
          {!isRequired && !hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
        </label>
      )}
      {!!helper && (
        <span className={classNames("help has-text-weight-normal mb-1")}>
          {helper}
        </span>
      )}
      <section className="buttons mb-0">
        {options.map(option => (
          <button
            index={option}
            className={classNames("button", {
              "is-primary": values.includes(option),
            })}
            value={option}
            type="button"
            onClick={handleOnSelect}
          >
            {option}
          </button>
        ))}
      </section>
      <p className="help mt-0 mb-1 is-danger">
        <ErrorMessage name={name} />
      </p>
    </div>
  )
}

export default FormButtonSelect
